import React from "react"
import { graphql } from 'gatsby'
import CategoryLayout from "../components/layout-category"
import PostExcerpt from "../components/post-excerpt"
import SEO from '../components/seo'


export default class TagTemplate extends React.Component {
  render() {
    const { pageContext, data } = this.props;
    const { category } = pageContext;
    return (
      <CategoryLayout>
        <div className="category">
          <SEO title={category} />

          <h1>{category.charAt(0).toUpperCase() + category.slice(1)}</h1>

          {data.allMarkdownRemark.edges.map(({ node }) => (
              <div key={node.id}>

                <PostExcerpt
                title={node.frontmatter.title}
                date={node.frontmatter.date}
                category={node.frontmatter.category}
                excerpt={node.excerpt}
                slug={node.fields.slug} />

              </div>
            ))}

        </div>
      </CategoryLayout>
    );
  }
}

export const pageQuery = graphql`
  query CategoryPage($category: String) {
    allMarkdownRemark(
      limit: 1000
      filter: { frontmatter: { category: { eq: $category } published: { eq: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          excerpt
          frontmatter {
            title
            tags
            date(formatString: "MMMM DD, YYYY")
            category
          }
        }
      }
    }
  }
`;
