import React from 'react'
import PropTypes from 'prop-types'

import SlimHeader from './slim-header'
import '../style/links.css'

const CategoryLayout = ({ children }) => (
  <div style={{
    background: '#fefffc',
    minHeight: '100vh'
  }}>
    <SlimHeader />
    <div
      class="category"
      style={{
        margin: `0 auto`,
        maxWidth: 1080,
        padding: `50px 1.0875rem 1.45rem`,
        paddingTop: 0,
      }}
    >
      {children}
    </div>
  </div>
)

CategoryLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CategoryLayout
